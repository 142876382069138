<template>
  <v-container class="fill-height">
    <!-- Header -->
    <app-header
      :title="myEvent.name"
      :level="1"
      :btn="false"
      :loading="loading"
    />

    <!-- Scan -->
    <qrcode-scan
      @findAttendee="validateTicket"
      class="rounded-lg overflow-hidden"
    />

    <!-- Attendees List -->
    <v-dialog
      v-model="dialogList"
      persistent
      fullscreen
      style="z-index: 10"
      transition="slide-x-reverse-transition"
    >
      <v-card tile>
        <v-toolbar color="primary" flat dark>
          <!-- back -->
          <v-btn @click="dialogList = false" icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <!-- title -->
          <v-toolbar-title> Listado de Asistentes </v-toolbar-title>
          <!-- actins -->
          <v-btn disabled icon />
        </v-toolbar>

        <v-card-text>
          <v-text-field
            class="mt-5"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          hide-default-header
          :search="search"
          :items="attendees"
          :items-per-page="50"
        >
          <template v-slot:body="{ items }">
            <tbody v-if="items.length > 0">
              <tr v-for="(item, index) in items" :key="item.uid">
                <v-divider v-if="index > 0" />
                <v-list-item two-line @click="filter(item)">
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize | font-weight-medium"
                    >
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ item.rut }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip
                      :color="item.activeTicket ? 'success' : 'error'"
                      outlined
                      small
                    >
                      <span v-if="!item.checkIn" class="font-weight-bold">
                        {{ item.activeTicket ? "Activo" : "Desactivado" }}
                      </span>
                      <span v-else class="font-weight-bold">
                        {{ item.checkIn.state ? "Ingresado" : "Rechazado" }}
                      </span>
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <div class="pa-3 text-center">Asistente no esta en lista</div>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- Loading -->
    <v-overlay opacity="1" :value="loading" absolute>
      <v-card>
        <v-card-text class="text-center">
          <v-progress-circular indeterminate color="grey" />
          <p class="mb-0 mt-3">Cargando Asistentes</p>
        </v-card-text>
      </v-card>
    </v-overlay>
    <!-- ./Loading -->

    <!-- [validation] -->
    <ui-validation
      v-if="valid"
      v-model="valid"
      :result="result"
      style="z-index: 10"
      @close="valid = false"
    />

    <!-- [button] -->
    <v-btn
      color="accent"
      class="mt-3"
      @click="dialogList = true"
      dark
      fixed
      top
      right
      style="z-index: 9"
      fab
    >
      <v-icon>mdi-account-search-outline</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import QrcodeScan from "../../components/Scan.vue";
import { tableAttendeesScan } from "../../assets/settings";

import uiValidation from "../../components/scan/Validation.vue";

import { db } from "@/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

let unsubscribe;

// 2023
import AppHeader from "@/layouts/components/Header.vue";

export default {
  components: { AppHeader, QrcodeScan, uiValidation },

  data: () => ({
    loading: false,
    dialogList: false,
    result: {},
    //
    search: "",
    headers: tableAttendeesScan,

    // ui
    valid: false,
  }),

  computed: {
    ...mapState("auth", ["user", "desktop"]),
    ...mapState("events", ["myEvent"]),
    ...mapState("attendees", ["attendees"]),
  },

  methods: {
    ...mapActions("events", ["readerEvent"]),
    ...mapActions("attendees", ["readerAttendeesRT", "scanFindAttendee"]),

    validateTicket(result) {
      this.result = result;
      this.valid = true;
    },

    async filter(item) {
      await this.scanFindAttendee({ id: item.rut, event: item.event }).then(
        (resp) => {
          this.dialogList = false;
          this.validateTicket(resp);
        }
      );
    },

    async foo() {
      const q = query(
        collection(db, `companies/${this.user.companyId}/junction`),
        where("eventId", "==", this.$route.params.uid)
      );

      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(_.merge({ event: this.$route.params.uid }, doc.data()));
        });
        this.$store.commit("attendees/setAttendees", items);
      });
    },
  },

  async created() {
    this.loading = true;
    await this.readerEvent(this.$route.params.uid);
    this.foo();
    this.loading = false;

    this.$root.$emit("app-bar", this.myEvent.name, 1);
  },

  beforeDestroy() {
    unsubscribe();
    this.$store.commit("events/set_myEvent", {});
  },
};
</script>

<style lang="scss" scoped>
#my-scan {
  height: 100%;
}
</style>